import React, { useState } from 'react';
import { Avatar, Box, Paper, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';
import { Chip } from 'components/chip';
import { SvgWrapper } from 'components/svg';
import _ from 'lodash';

interface CardDataProps {
  name: string;
  logo: string;
  id: string;
  onClick?: () => void;
}

const VaultMainOrgCard = ({ name, logo, id, onClick }: CardDataProps) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '.75rem',
        height: '7rem',
        width: 'auto',
        borderRadius: '.75rem',
        border: '.063rem solid #F5F6F7',
        cursor: 'pointer',
        color: '#667085',
        boxShadow: '0px 1.5px 4px -1px rgba(20, 28, 40, 0.05)',
        '&:hover': {
          boxShadow: '4px 4px 6px 3px rgba(124, 138, 169, 0.12)',
          color: '#475467'
        }
      }}
      onClick={onClick}
      onMouseOver={() => setIsActive(!isActive)}
    >
      <Stack
        direction="row"
        gap=".5rem"
        sx={{
          height: '100%',
          width: 'auto'
        }}
      >
        <Box
          sx={{
            width: '5.5rem',
            height: '5.5rem',
            borderRadius: '.75rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#FCFCFD'
          }}
        >
          <Avatar
            src={logo}
            sx={{
              width: '3rem',
              height: '3rem'
            }}
          />
        </Box>

        <Stack
          direction="column"
          display="flex"
          justifyContent="space-around"
          sx={{
            height: '100%'
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: { md: '10rem', xl: '13rem' }
            }}
          >
            <Typography
              variant="bodyMediumSemibold"
              color="inherit"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '80%',
                letterSpacing: -0.9
              }}
            >
              {_.truncate(`${_.startCase(name)}`, {
                length: 25,
                omission: '...'
              })}
            </Typography>

            {isActive && (
              <Box sx={{ display: 'flex' }}>
                <SvgWrapper
                  icon={ChevronRight}
                  width="1rem"
                  height="1rem"
                  styleOverrides={{ stroke: '#98A2B3', fill: 'none' }}
                />
              </Box>
            )}
          </Stack>

          <Box>
            <Chip label={`ID / ${id}`} size="xs" color="inherit" />
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default VaultMainOrgCard;
