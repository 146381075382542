import React from 'react';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Box, Stack, Typography } from '@mui/material';
import { theme } from 'themes/theme';
import IndicatorCard from './cards/indicatorCard';
import {
  TopProductsChart,
  TotalPrcValueChart,
  TopBranchesChart
} from './charts';
import { useGetProductReceiptsSummaryQuery } from 'services/api/analyticsApi';
import { formattedNumber } from 'utilities/helperFunc/formatter';

Chart.register(CategoryScale);

const AnalyticsPage = () => {
  const { data: productReceiptSummary } = useGetProductReceiptsSummaryQuery({
    params: {
      start: '2024-01-01'
    }
  });

  const indicatorItems = productReceiptSummary
    ? [
        {
          title: 'Total PRC Value',
          value: formattedNumber(productReceiptSummary.total_value, true)
        },
        {
          title: 'Total PRC Issued',
          value: formattedNumber(productReceiptSummary.total_count)
        },
        {
          title: 'Pickup Rates',
          value: `${productReceiptSummary.pickup_rate}%`
        }
      ]
    : null;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: '2.06rem'
        }}
      >
        <Stack
          width="inherit"
          direction="column"
          spacing={0.25}
          sx={{ textAlign: 'left' }}
        >
          <Typography
            variant="h7Semibold"
            sx={{
              color: `${theme.palette.common.subheading}`,
              fontWeight: 600,
              lineHeight: '1.5rem',
              letterSpacing: '0.005rem'
            }}
          >
            Analytics
          </Typography>
          <Typography
            variant="bodyMediumMedium"
            sx={{
              color: '#98A2B3'
            }}
          >
            Data driven ATC management decisions
          </Typography>
        </Stack>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1.375rem',
          width: 'inherit'
        }}
      >
        {indicatorItems?.map((item, index) => (
          <IndicatorCard key={index} title={item.title} value={item.value} />
        ))}
      </Box>
      <Box
        sx={{
          marginTop: '2rem',
          display: 'flex',
          gap: '1.75rem',
          width: 'inherit',
          backgroundColor: '#FFFFFF',
          borderRadius: '0.5rem',
          border: '.063rem solid #F5F6F7',
          padding: '1rem'
        }}
      >
        <TotalPrcValueChart />
      </Box>
      <Box
        sx={{
          marginTop: '2rem',
          display: 'flex',
          gap: '1.75rem',
          width: 'inherit',
          backgroundColor: '#FFFFFF',
          borderRadius: '0.5rem',
          border: '.063rem solid #F5F6F7',
          padding: '1rem'
        }}
      >
        <TopBranchesChart />
      </Box>
      <Box
        sx={{
          marginTop: '2rem',
          display: 'flex',
          gap: '1.75rem',
          width: 'inherit',
          backgroundColor: '#FFFFFF',
          borderRadius: '0.5rem',
          border: '.063rem solid #F5F6F7',
          padding: '1rem'
        }}
      >
        <TopProductsChart />
      </Box>
    </Box>
  );
};

export default AnalyticsPage;
