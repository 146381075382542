import React, { PropsWithChildren } from 'react';
import { Box, Dialog, Stack, Typography } from '@mui/material';
import { ReactComponent as BankIcon } from 'assets/svg/bank.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/x.svg';
import { SvgWrapper } from 'components/svg';
import ShowInfo from 'components/info/showInfo';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useEndorseProductReceiptMutation } from 'services/api/productReceiptApi';
import { useAppSelector } from 'services/hook';
import { ProductReceiptApiData } from 'services/types/productReceipt';
import { Button } from 'components/button';
import { OrganizationType } from 'services/enums/organization';

type ProductReceiptWorkflowModalProps = {
  open: boolean;
  onClose: () => void;
  productReceipt?: ProductReceiptApiData;
};

const ProductReceiptEndorsementWorkflowModal: React.FC<
  PropsWithChildren<ProductReceiptWorkflowModalProps>
> = ({ open, onClose, productReceipt }) => {
  const { organizationType } = useAppSelector(state => state.auth);
  const { productReceiptId } = useParams();
  const [endorseProductReceipt, { isLoading: isLoadingEndorsement }] =
    useEndorseProductReceiptMutation();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '24.5rem',
            borderRadius: '0.75rem',
            border: '.125rem solid #F5F6F7',
            backgroundColor: '#FCFCFD'
          }
        }
      }}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1.25rem',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            borderBottom: '.125rem solid #F5F6F7'
          }}
        >
          <Stack width="100%" direction="row" justifyContent="space-between">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '2.25rem',
                width: '2.25rem',
                borderRadius: '6.25rem',
                backgroundColor: '#E5EEFF',
                padding: '0.5rem',
                boxShadow: '0px 0px 0px 4px rgba(200, 219, 255, 0.20)',
                marginBottom: '1rem'
              }}
            >
              <SvgWrapper
                icon={BankIcon}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{
                  opacity: 0.8
                }}
                color="#C1D6FF"
              />
            </Box>
            <Box onClick={() => onClose()}>
              <SvgWrapper icon={CloseIcon} width="1.5rem" height="1.5rem" />
            </Box>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center'
            }}
          >
            <Typography variant="h7Semibold" color="#344054">
              {organizationType === OrganizationType.MERCHANT
                ? 'Issue to Customer'
                : 'Endorse to Customer'}
            </Typography>
            <Typography
              variant="bodyMediumRegular"
              color="#667085"
              sx={{ mt: '.5rem' }}
            >
              Carefully review PRC before assigning to branch.Do you wish to
              proceed?
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            margin: '1rem .75rem',
            padding: '1.25rem 2.5rem',
            backgroundColor: 'rgba(102, 112, 133, 0.02)',
            borderRadius: '0.5rem',
            border: '.063rem solid #F5F6F7',
            display: 'flex',
            flexDirection: 'column',
            gap: '1.25rem'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              PRC Code
            </Typography>
            <Typography variant="bodyMediumMedium" color="#475467">
              #{productReceipt?.product_receipt_id}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              Merchant
            </Typography>
            <Typography variant="bodyMediumMedium" color="#475467">
              {productReceipt?.merchant.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              On behalf of
            </Typography>
            <Typography variant="bodyMediumMedium" color="#475467">
              {productReceipt?.holders[1].name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              Product
            </Typography>
            <Typography variant="bodyMediumMedium" color="#475467">
              {productReceipt?.product.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              Quantity
            </Typography>
            <Typography variant="bodyMediumMedium" color="#475467">
              {`${productReceipt?.total_quantity} ${productReceipt?.product.unit}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '.063rem solid #F5F6F7',
            padding: '1.25rem 1.5rem',
            marginBottom: '0.25rem'
          }}
        >
          <Button
            color="grey"
            transparent
            size="lg"
            text={
              organizationType === OrganizationType.MERCHANT
                ? 'Issue later'
                : 'Endorse later'
            }
            width="48%"
            onClick={() => onClose()}
          />
          <Button
            submit
            color="primary"
            size="lg"
            text="Proceed"
            width="48%"
            loadingIcon={isLoadingEndorsement}
            onClick={() => {
              if (!_.isUndefined(productReceiptId)) {
                endorseProductReceipt({
                  data: {
                    product_receipts: [productReceiptId]
                  }
                })
                  .unwrap()
                  .then(() => {
                    ShowInfo({
                      message: 'PRC endorsed successfully',
                      subText: 'PRC has been endorsed',
                      type: 'success',
                      componentType: 'toast',
                      hideProgressBar: false
                    });
                    onClose();
                  })
                  .catch(() => {
                    ShowInfo({
                      message: 'PRC endorsement failed',
                      subText: 'PRC endorsement not successful',
                      type: 'error',
                      componentType: 'toast',
                      hideProgressBar: false
                    });
                  });
              }
            }}
          />
        </Box>
      </>
    </Dialog>
  );
};
export default ProductReceiptEndorsementWorkflowModal;
