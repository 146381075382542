import React from 'react';
import {
  Box,
  Stack,
  Typography,
  Divider,
  Dialog,
  DialogContent
} from '@mui/material';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as Home } from 'assets/svg/home.svg';
import { Button } from 'components/button';
import { FormSelect } from 'components/form';
import { Resolver, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { workflowTypeValidator } from './validators/workflowValidators';

import _ from 'lodash';

export type AddWorkflowModalProps = {
  open: boolean;
  onClose: () => void;
  useCaseOptions: {
    title: string;
    value: string;
    subtitle: string;
  }[];
};

type FormValues = {
  workflowType: string;
};

const AddWorkflowModal: React.FC<AddWorkflowModalProps> = ({
  open,
  onClose,
  useCaseOptions
}) => {
  const { getValues, control, handleSubmit, clearErrors } = useForm<FormValues>(
    {
      mode: 'onTouched',
      defaultValues: {
        workflowType: ''
      },
      resolver: yupResolver(
        workflowTypeValidator
      ) as unknown as Resolver<FormValues>
    }
  );

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = () => {
    const workflowTypeValue = getValues('workflowType');
    navigate(
      `/workflows-builder?create=true&workflowType=${workflowTypeValue}`
    );
  };

  const onDialogClose = () => {
    clearErrors();
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        sx={{
          padding: '1.25rem',
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '24.5rem',
              borderRadius: '0.75rem',
              border: '.125rem solid #F5F6F7',
              backgroundColor: '#FCFCFD'
            }
          }
        }}
        open={open}
        onClose={onDialogClose}
      >
        <DialogContent sx={{ borderRadius: '.75rem .75rem 0rem 0rem' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Stack
              sx={{
                flexDirection: 'row',
                background: '#FCFCFD'
              }}
            >
              {/* New Workflow text column */}
              <Box
                display="flex"
                sx={{
                  alignItems: 'flex-start',
                  gap: '1.25rem',
                  width: '20rem',
                  alignSelf: 'stretch',
                  borderRadius: '0.75rem 0.75rem 0rem 0rem',
                  borderBottom: '.125rem solid #FCFCFD'
                }}
              >
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      borderRadius: '6.25rem',
                      background: '#F8FBFE',
                      padding: '.25rem',
                      boxShadow: '0px 0px 0px 4px rgba(195, 215, 253, 0.20)',
                      marginBottom: '1.25rem'
                    }}
                  >
                    <SvgWrapper
                      icon={Home}
                      width="1.125rem"
                      height="1.125rem"
                      styleOverrides={{ fill: '#C3D7FD', stroke: 'none' }}
                    />
                  </Box>
                  <Typography
                    variant="h7Semibold"
                    color="#344054"
                    style={{
                      fontSize: '1rem',
                      fontWeight: 600,
                      lineHeight: '1.375rem',
                      marginBottom: '.125rem'
                    }}
                  >
                    New Workflow
                  </Typography>
                  <Typography
                    variant="bodyMediumRegular"
                    style={{
                      color: '#667085'
                    }}
                  >
                    Create a workflow for one of the following to match your
                    internal processes.
                  </Typography>
                </Stack>
              </Box>
            </Stack>

            <Stack
              sx={{
                mt: '1.875rem',
                mb: '1.875rem'
              }}
            >
              <FormSelect<FormValues>
                name="workflowType"
                options={useCaseOptions.map(option => ({
                  title: option.title,
                  value: option.value
                }))}
                label={'WORKFLOW TYPE'}
                control={control}
                renderSelected={(value: string) => _.startCase(value)}
              />
            </Stack>

            <Divider />

            {/* Bottom Buttons row */}
            <Stack
              sx={{
                display: 'flex',
                paddingTop: '1.25rem',
                paddingBottom: '1.25rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '.75rem',
                alignSelf: 'stretch',
                flexDirection: 'row'
              }}
            >
              <Button
                color="grey"
                transparent
                size="md"
                onClick={onClose}
                text="Cancel"
                styleOverrides={{
                  borderRadius: '.5rem',
                  padding: '0.75rem',
                  border: '.094rem solid #F2F4F7',
                  color: '#475467',
                  flex: '1 0 0'
                }}
              />

              <Button
                color="primary"
                size="md"
                submit
                text="Create Workflow"
                styleOverrides={{
                  borderRadius: '.5rem',
                  padding: '0.75rem',
                  flex: '1 0 0'
                }}
              />
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default AddWorkflowModal;
