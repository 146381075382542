import React, { useContext } from 'react';
import {
  Avatar,
  Stack,
  Toolbar,
  Typography,
  Button as MUIButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import chevronLeft from 'assets/svg/chevronLeft.svg';
import { Button } from 'components/button';
import {
  CreateProductReceiptContext,
  FormValues
} from '../context/createProductReceiptContext';
import ShowInfo from 'components/info/showInfo';
import {
  CreateProductReceiptQueryArgs,
  useCreateProductReceiptMutation
} from 'services/api/productReceiptApi';
import { removeItem } from 'utilities/helperFunc/localStore';

const CreateProductReceiptBottomNav = () => {
  const { activeStep, data, steps, handleNext, handlePrev, selectedType } =
    useContext(CreateProductReceiptContext);
  const [createProductReceipt, { isLoading }] =
    useCreateProductReceiptMutation();

  const navigate = useNavigate();

  const onSubmit = async (entry: FormValues[]) => {
    const CreateProductReceiptData: CreateProductReceiptQueryArgs[] = [];
    entry.forEach(entry =>
      CreateProductReceiptData.push({
        customer: entry.customer_name,
        bank: entry.bank,
        product: entry.product,
        document_files: [],
        document_metadata: [],
        total_quantity: Number(entry.quantity),
        quoted_price: Number(entry.amount),
        quoted_price_currency: 'ngn',
        quoted_price_expiry: entry.expiry_date
      })
    );
    await createProductReceipt(CreateProductReceiptData)
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Product Receipt Created',
          subText: 'PRC has been added to your vault',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        navigate('/product-receipts');
        removeItem('createProductReceiptFormData');
        removeItem('createProductReceiptStep');
        removeItem('createProductReceiptSelectedType');
      })
      .catch(() => {
        ShowInfo({
          message: 'Product Receipt Created',
          subText: 'PRC has not been created!',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };

  return (
    <Toolbar
      sx={{
        width: 'inherit',
        padding: '0.75rem 3rem',
        height: '100%',
        borderTop: '0.063rem solid #F5F6F7',
        background: '#fff'
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MUIButton
          variant="text"
          startIcon={
            <Avatar
              src={chevronLeft}
              sx={{ width: '1.25rem', height: '1.25rem' }}
            />
          }
          onClick={() => {
            navigate('/product-receipts');
            removeItem('createProductReceiptFormData');
            removeItem('createProductReceiptStep');
            removeItem('createProductReceiptSelectedType');
          }}
        >
          <Typography variant="bodyMediumSemibold" color="#344054">
            Exit Product Receipt Creation
          </Typography>
        </MUIButton>

        <Stack direction="row" gap=".75rem">
          {activeStep !== 0 && (
            <Button
              color="grey"
              transparent
              size="md"
              text="Back"
              styleOverrides={{
                width: '10rem',
                padding: '.75rem',
                borderRadius: '.5rem'
              }}
              onClick={handlePrev}
            />
          )}

          <Button
            color="primary"
            size="md"
            text={
              activeStep === steps.length - 1
                ? 'Create Product Receipt'
                : activeStep === 1
                  ? 'Review'
                  : 'Next'
            }
            styleOverrides={{
              minWidth: '10rem',
              padding: '.75rem',
              borderRadius: '.5rem'
            }}
            onClick={() => {
              if (activeStep === steps.length - 1) {
                onSubmit(data);
              } else {
                handleNext();
              }
            }}
            disabled={
              (selectedType === null && activeStep === 0) ||
              (activeStep === 1 && data.length < 1)
            }
            loadingIcon={isLoading}
          />
        </Stack>
      </Stack>
    </Toolbar>
  );
};

export default CreateProductReceiptBottomNav;
