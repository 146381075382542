import React from 'react';
import { Divider, Paper, Stack, Typography } from '@mui/material';
import { formattedNumber } from 'utilities/helperFunc/formatter';

interface CardDataProps {
  holders_name: string;
  product_name: string;
  total_quantity: number;
  unit: string;
  quoted_price: number;
  status: number;
}

const ProductReceiptDetailsCard = ({
  holders_name,
  quoted_price,
  product_name,
  total_quantity,
  unit
}: CardDataProps) => {
  // TODO Task #969
  // const chipData: { id: number; text: string; color: 'default' | 'success' }[] =
  //   [
  //     {
  //       id: 0,
  //       text: 'Pending',
  //       color: 'default'
  //     },
  //     {
  //       id: 5,
  //       text: 'Endorsed',
  //       color: 'success'
  //     },
  //     {
  //       id: 10,
  //       text: 'Pickup Complete',
  //       color: 'success'
  //     }
  //   ];

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '1.25rem',
        gap: '1rem',
        width: 'auto'
      }}
    >
      <Stack
        width="30rem"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack direction="column" display="flex" justifyContent="space-between">
          <Typography variant="bodyLargeSemibold" color="#475467">
            {holders_name}
          </Typography>

          <Stack
            width="auto"
            direction="row"
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="bodyMediumRegular" color="#98A2B3">
              {product_name}
            </Typography>

            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                borderRadius: '0.125rem',
                borderLeft: '0.0625rem solid #EAECF0',
                borderRight: '0.0625rem solid #EAECF0',
                margin: '0rem 0.5rem',
                height: '1.187rem'
              }}
            />

            <Typography variant="bodyMediumRegular" color="#98A2B3">
              Quantity/ {formattedNumber(total_quantity, false)} {unit}
            </Typography>
          </Stack>

          <Typography
            variant="h7Semibold"
            color="#475467"
            paddingBottom="0.25rem"
            textAlign="left"
            marginTop="1rem"
          >
            {formattedNumber(quoted_price * total_quantity, true)}
          </Typography>
        </Stack>

        {/* TODO Task #969 */}
        {/* <Box>
          {chipData.map((chip, index) => {
            return (
              <Box key={index}>
                {status === chip.id && (
                  <Chip label={chip.text} size="sm" color={chip.color} icon />
                )}
              </Box>
            );
          })}
        </Box> */}
      </Stack>
    </Paper>
  );
};

export default ProductReceiptDetailsCard;
