import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';
import { Button } from 'components/button';
import { Table } from 'components/table';
import { formattedDate, formattedNumber } from 'utilities/helperFunc/formatter';
import { ProductReceiptApiData } from 'services/types/productReceipt';
import { OrganizationType } from 'services/enums/organization';
import { Chip } from 'components/chip';

interface DashboardProductReceiptTableProps {
  data: ProductReceiptApiData[];
  organizationType: string;
  isLoading: boolean;
}

const DashboardProductReceiptTable = ({
  data,
  organizationType,
  isLoading
}: DashboardProductReceiptTableProps) => {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<ProductReceiptApiData>();
  const columns = [
    columnHelper.accessor('product_receipt_id', {
      header: 'PRC CODE',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {info.getValue()}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('product', {
      header: 'PRODUCT',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {info.getValue().name} {info.getValue().unit}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('quoted_price', {
      header: 'TOTAL VALUE',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {formattedNumber(info.getValue(), true)}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('total_quantity', {
      header: 'QUANTITY',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {formattedNumber(info.getValue(), false)}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('created_on', {
      header: 'ISSUE DATE',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {formattedDate(`${info.getValue()}`, 'Do MMM, YYYY hh:ma')}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    })
  ] as ColumnDef<ProductReceiptApiData>[];
  // Cast is a workaround due to issues with tansatck table
  // See https://github.com/TanStack/table/issues/4302

  const type = (organizationType: string) => {
    if (organizationType === OrganizationType.MERCHANT) {
      columns.splice(
        2,
        0,
        columnHelper.accessor('holders', {
          id: '0_holders',
          header: 'BANK',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()[0].name}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptApiData>,
        columnHelper.accessor('holders', {
          id: '1_holders',
          header: 'CUSTOMER',
          cell: info => <Chip label={info.getValue()[1].name} size="sm" />,
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptApiData>
      );
    }
    if (organizationType === OrganizationType.LENDER) {
      columns.splice(
        2,
        0,
        columnHelper.accessor('merchant', {
          header: 'MERCHANT',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue().name}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptApiData>,
        columnHelper.accessor('holders', {
          header: 'CUSTOMER',
          cell: info => <Chip label={info.getValue()[1].name} size="sm" />,
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptApiData>
      );
    }
    if (organizationType === OrganizationType.DISTRIBUTOR) {
      columns.splice(
        2,
        0,
        columnHelper.accessor('holders', {
          header: 'BANK',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()[0].name}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptApiData>,
        columnHelper.accessor('merchant', {
          header: 'MERCHANT',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue().name}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptApiData>
      );
    }
  };

  type(organizationType);

  const handleRowClick = (rowData: ProductReceiptApiData) => {
    const merchant_id = rowData.merchant.organization_id;
    const distributor = rowData.holders.find(
      obj => obj.organization_type === 'distributor'
    );
    const distributor_id = distributor ? distributor.public_id : '';

    navigate(
      `/product-receipts/central-vault/${merchant_id}/${distributor_id}/${rowData.product_receipt_id}/details`
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="1.25rem"
        spacing={1}
        width="100%"
        border=".063rem solid #F5F6F7"
        borderRadius="0.5rem 0.5rem 0rem 0rem"
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          flex="1 0 0"
        >
          <Typography variant="bodyMediumSemibold">
            New PRC&apos;s Received
          </Typography>
          <Typography
            variant="bodyMediumMedium"
            color="#98A2B3"
            sx={{ width: 'auto' }}
          >
            PRC&apos;s received from the merchant
          </Typography>
        </Stack>
        <Button
          color="grey"
          transparent
          size="sm"
          icon={ChevronRight}
          iconPosition="end"
          text="See all"
          onClick={() => navigate('/product-receipts')}
        />
      </Stack>

      <Table<ProductReceiptApiData>
        hover
        columns={columns}
        data={data}
        showSearch={false}
        showFooter={false}
        enableFilter={false}
        filterColumns={['']}
        loadingTable={isLoading}
        onClickRow={rowData => handleRowClick(rowData)}
      />
    </Box>
  );
};

export default DashboardProductReceiptTable;
