import React, { useMemo, useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { ReactComponent as plusIcon } from 'assets/svg/plus.svg';

import { TabContainer } from 'components/tabs';
import { Button } from 'components/button';

import PublishedWorkflowsTable from './publishedWorkflowsTable';
import DraftWorkflowsTable from './draftWorkflowsTable';
import AddWorkflowModal from './addWorkflowModal';
import type { AddWorkflowModalProps } from './addWorkflowModal';
import {
  useGetWorkflowsQuery,
  useGetWorkflowUseCasesQuery,
  WorkflowUseCaseApiData
} from 'services/api/workflowApi';
import { WorkflowSchemaApiData } from 'services/types/workflow';
import { WithIdTransformed } from 'services/types/common';

import _ from 'lodash';

const WorkflowsPage = () => {
  const [addWorkflowModalOpen, setAddWorkflowModalOpen] = useState(false);

  const { data: workflowsData } = useGetWorkflowsQuery({
    params: { include_drafts: 'True' }
  });
  const { data: useCaseData } = useGetWorkflowUseCasesQuery({});

  const missingUseCases = useMemo(() => {
    const missingUseCases: AddWorkflowModalProps['useCaseOptions'] = [];
    if (useCaseData && workflowsData) {
      useCaseData.forEach(useCase => {
        if (!Object.hasOwn(workflowsData, useCase.value)) {
          missingUseCases.push({
            title: _.startCase(useCase.value),
            value: useCase.value,
            subtitle: useCase.description
          });
        }
      });
    }
    return missingUseCases;
  }, [Object.keys(useCaseData ?? {})]);

  const publishedWorkflows = useMemo(() => {
    const publishedWorkflowData: WithIdTransformed<WorkflowSchemaApiData> = {};
    if (workflowsData) {
      Object.entries(workflowsData).forEach(([use_case, workflow]) => {
        if (workflow.published) {
          publishedWorkflowData[use_case] = workflow;
        }
      });
    }
    return publishedWorkflowData;
  }, [Object.keys(workflowsData ?? {})]);

  const draftWorkflows = useMemo(() => {
    const draftWorkflowData: WithIdTransformed<WorkflowSchemaApiData> = {};
    if (workflowsData) {
      Object.entries(workflowsData).forEach(([use_case, workflow]) => {
        if (!workflow.published) {
          draftWorkflowData[use_case] = workflow;
        }
      });
    }
    return draftWorkflowData;
  }, [Object.keys(workflowsData ?? {})]);

  const getDescriptions = (
    tableData: WithIdTransformed<WorkflowSchemaApiData>
  ) => {
    const description: WorkflowUseCaseApiData[] = [];
    if (tableData && useCaseData) {
      Object.keys(tableData).map(el => {
        const matchedCase = useCaseData.find(item => item.value === el);
        matchedCase && description.push(matchedCase);
      });
    }
    return description;
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1.75rem'
        }}
      >
        <Stack gap=".25rem" width="inherit">
          <Typography
            variant="h6Bold"
            color="#475467"
            sx={{ width: '10.75rem' }}
          >
            My workflows
          </Typography>
          <Typography
            variant="bodyMediumMedium"
            color="#98A2B3"
            sx={{ width: '23rem' }}
          >
            See all workflows in your organization
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setAddWorkflowModalOpen(true);
            }}
            icon={plusIcon}
            iconPosition="start"
            text="Create Workflow"
            styleOverrides={{ padding: '0.5rem 0.75rem' }}
          />
        </Box>
      </Box>
      <TabContainer
        tabs={[
          {
            labelText: 'Published',
            content: (
              <PublishedWorkflowsTable
                workflows={publishedWorkflows}
                description={getDescriptions(publishedWorkflows)}
              />
            )
          },
          {
            labelText: 'Drafts',
            content: (
              <DraftWorkflowsTable
                workflows={draftWorkflows}
                description={getDescriptions(draftWorkflows)}
              />
            )
          }
        ]}
      />

      <AddWorkflowModal
        open={addWorkflowModalOpen}
        onClose={() => setAddWorkflowModalOpen(false)}
        useCaseOptions={missingUseCases}
      />
    </Box>
  );
};

export default WorkflowsPage;
