import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProductReceiptType } from 'services/types/productReceipt';
import { getItem, setItem } from 'utilities/helperFunc/localStore';

export type RequestDetails = {
  id: number;
};

export interface FormValues {
  customer_name: string;
  bank: string;
  product: string;
  quantity: string;
  amount: string;
  expiry_date: string;
}

const initialValues: FormValues[] = [
  {
    customer_name: '',
    bank: '',
    product: '',
    quantity: '',
    amount: '',
    expiry_date: ''
  }
];

type CreateProductReceiptContextTypes = {
  data: FormValues[];
  setData: React.Dispatch<React.SetStateAction<FormValues[]>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  request: RequestDetails | null;
  setRequest: React.Dispatch<React.SetStateAction<RequestDetails | null>>;
  selectedType: ProductReceiptType | null;
  setSelectedType: React.Dispatch<
    React.SetStateAction<ProductReceiptType | null>
  >;
  steps: { label: string }[];
  handleNext: (newData?: FormValues) => void;
  handlePrev: () => void;
};

const CreateProductReceiptContextDefaults: CreateProductReceiptContextTypes = {
  data: initialValues,
  setData: () => undefined,
  activeStep: 0,
  setActiveStep: () => undefined,
  request: null,
  setRequest: () => undefined,
  selectedType: null,
  setSelectedType: () => undefined,
  steps: [],
  handleNext: () => undefined,
  handlePrev: () => undefined
};

export const CreateProductReceiptContext =
  createContext<CreateProductReceiptContextTypes>(
    CreateProductReceiptContextDefaults
  );

const CreateProductReceiptContextWrapper = ({
  children
}: PropsWithChildren) => {
  const [request, setRequest] = useState<RequestDetails | null>(null);
  const [data, setData] = useState<FormValues[]>(
    getItem<FormValues[]>('createProductReceiptFormData', initialValues)
  );
  const [activeStep, setActiveStep] = useState<number>(() =>
    getItem<number>('createProductReceiptStep', 0)
  );
  const [selectedType, setSelectedType] = useState<ProductReceiptType | null>(
    () => getItem('createProductReceiptSelectedType', null)
  );

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const requestIdParam = searchParams.get('requestId');
  const requestId = requestIdParam === 'null' ? null : requestIdParam;

  const steps = [
    { label: 'Select Type' },
    { label: 'Product Receipt Details' },
    { label: 'Review Details' }
  ];

  const handleNext = () => {
    const nextStep = activeStep + 1;
    const requestId = request?.id ? request.id : 'null';
    if (nextStep < steps.length) {
      setActiveStep(nextStep);
      navigate(`/product-receipts/create/?requestId=${requestId}`);
    }
  };

  const handlePrev = () => {
    const prevStep = activeStep - 1;
    const requestId = request?.id ? request.id : 'null';
    if (prevStep >= 0) {
      setActiveStep(prevStep);
      navigate(`/product-receipts/create/?requestId=${requestId}`);
    }
  };

  useEffect(() => {
    setItem('createProductReceiptStep', activeStep);
    setItem('createProductReceiptSelectedType', selectedType);
    setItem('createProductReceiptFormData', data);
  }, [activeStep, selectedType, data]);

  useEffect(() => {
    if (requestId !== null) {
      setRequest({ id: 1 });
      setActiveStep(1);
    } else {
      setRequest(null);
    }
  }, [requestId]);

  const values = {
    handleNext,
    handlePrev,
    steps,
    activeStep,
    setActiveStep,
    request,
    setRequest,
    selectedType,
    setSelectedType,
    data,
    setData
  };

  return (
    <CreateProductReceiptContext.Provider value={values}>
      {children}
    </CreateProductReceiptContext.Provider>
  );
};

export default CreateProductReceiptContextWrapper;
