import React from 'react';
import { theme } from 'themes/theme';
import { Box, Stack, Toolbar, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from 'assets/custom-svg/flux-logo.svg';
import { Button } from 'components/button';

const AuthNavbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const registerOrgPath = pathname.startsWith('/register');

  return (
    <Toolbar
      sx={{
        width: 'inherit',
        backgroundColor: `${theme.palette.common.white}`,
        height: '3.75rem',
        borderBottom: '.063rem solid #FCFCFD'
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: '0.75rem 1.8rem'
        }}
      >
        <Stack
          direction="row"
          gap={0.5}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            component="img"
            src={logo}
            sx={{ width: '1.8rem', height: '1.8rem' }}
          />
          <Typography
            component="div"
            sx={{
              color: `${theme.palette.common.logoColor}`,
              fontSize: '1.25rem',
              fontWeight: '700',
              lineHeight: '1.5rem',
              letterSpacing: '-0.01875rem'
            }}
          >
            Flux
          </Typography>
        </Stack>

        <Button
          color="primary"
          transparent
          size="sm"
          text={registerOrgPath ? 'Log in' : 'Sign up'}
          styleOverrides={{
            padding: '.5rem .75rem',
            width: '6rem',
            border: `.0625rem solid ${theme.palette.common.btnColor}`
          }}
          onClick={() =>
            registerOrgPath ? navigate('/login') : navigate('/register')
          }
        />
      </Stack>
    </Toolbar>
  );
};

export default AuthNavbar;
