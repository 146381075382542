import React, { useContext, useState } from 'react';
import { theme } from 'themes/theme';
import {
  Avatar,
  Box,
  Stack,
  Toolbar,
  Typography,
  Button as MUIButton
} from '@mui/material';
import chevronLeft from 'assets/svg/chevronLeft.svg';

import { Button } from 'components/button';
import { useSearchParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { WorkflowBuilderContext } from '../context/builderContext';
import { useSaveWorkflowMutation } from 'services/api/workflowApi';
import type { WorkflowStepApiRequestData } from 'services/api/workflowApi';

import _ from 'lodash';
import ShowInfo from 'components/info/showInfo';

const WorkflowsBuilderNavbar = () => {
  const [searchParams] = useSearchParams();
  const currentUseCase = searchParams.get('workflowType');

  const navigate = useNavigate();
  const { workflowSteps, currentWorkflowVersion } = useContext(
    WorkflowBuilderContext
  );
  const [saveWorkflow] = useSaveWorkflowMutation();

  const [saveInProgress, setSaveInProgress] = useState(false);
  const [publishInProgress, setPublishInProgress] = useState(false);

  const onClickSaveWorkflow = (shouldPublish: boolean) => {
    if (shouldPublish) {
      setPublishInProgress(true);
    } else {
      setSaveInProgress(true);
    }

    // Generate steps for api
    const apiSteps: WorkflowStepApiRequestData[] = [];
    workflowSteps.forEach((step, index) => {
      apiSteps.push({
        step_order: index + 1,
        step_title: step.title,
        step_description: step.description,
        step_type: 'approval',
        owned_by_role: step.ownedByRole,
        owned_by_team: step.ownedByTeam
      });
    });
    saveWorkflow({
      data: {
        steps: apiSteps,
        use_case: currentUseCase,
        should_publish: shouldPublish,
        version_id: currentWorkflowVersion
      }
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Workflow added successfully',
          subText: `Workflow has been ${shouldPublish ? 'published' : 'saved to draft'}`,
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        navigate('/workflows');
      })
      .catch(() => {
        ShowInfo({
          message: 'Workflow not added',
          subText: '',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
        //reset the state
        setSaveInProgress(false);
        setPublishInProgress(false);
      });
  };
  return (
    <Toolbar
      sx={{
        width: 'inherit',
        backgroundColor: `${theme.palette.common.appBackground}`,
        padding: '0.75rem 3rem',
        height: '100%',
        borderBottom: '0.063rem solid #F5F6F7'
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MUIButton
          variant="text"
          startIcon={
            <Avatar
              src={chevronLeft}
              sx={{ width: '1.25rem', height: '1.25rem' }}
            />
          }
          onClick={() => navigate('/workflows')}
        >
          <Typography
            variant="bodyMediumSemibold"
            sx={{
              color: `${theme.palette.common.labelColor}`
            }}
          >
            Back to dashboard
          </Typography>
        </MUIButton>

        <Box
          sx={{
            ml: '24%',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="bodyLargeSemibold"
            color={`${theme.palette.common.subheading}`}
          >
            {_.startCase(currentUseCase ?? '')}
          </Typography>
        </Box>

        <Stack
          direction="row"
          spacing={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: '0.75rem 1rem'
          }}
        >
          <Button
            color="grey"
            transparent
            size="sm"
            text="Save as draft"
            onClick={() => onClickSaveWorkflow(false)}
            disabled={saveInProgress || publishInProgress}
            loadingIcon={saveInProgress}
          />
          <Button
            color="primary"
            size="sm"
            text="Publish"
            onClick={() => onClickSaveWorkflow(true)}
            disabled={saveInProgress || publishInProgress}
            loadingIcon={publishInProgress}
          />
        </Stack>
      </Stack>
    </Toolbar>
  );
};

export default WorkflowsBuilderNavbar;
