import React from 'react';
import { Box, Drawer, Stack, Typography } from '@mui/material';
import { ReactComponent as Opened } from 'assets/svg/opened.svg';
import { ReactComponent as Filter } from 'assets/svg/filterLines.svg';
import { TabContainer } from 'components/tabs';
import { SvgWrapper } from 'components/svg';
import GeneralNotifications from './generalNotifications';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

const NotificationsDrawer: React.FC<DrawerProps> = ({ open, onClose }) => {
  const tabs = [
    {
      labelText: 'General',
      content: <GeneralNotifications />
    }
  ];

  const getStartingPoint = () => {
    const width = window.innerWidth;
    return (width / 12) * 2.3;
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          position: 'fixed',
          width: '29%',
          left: getStartingPoint(),
          boxShadow: 'none'
        }
      }}
      ModalProps={{
        slotProps: {
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0)'
            }
          }
        }
      }}
      transitionDuration={0}
    >
      <Box
        sx={{
          position: 'fixed',
          height: '100%',
          width: 'inherit',
          left: getStartingPoint(),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          border: '0.063rem solid #F2F4F7',
          transform: open
            ? 'translateX(0px)'
            : `translateX(-${getStartingPoint()}px)`,
          transition: 'transform 0.8s ease-in-out'
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          padding="1rem 1.5rem"
          spacing={1}
          justifyContent="space-between"
          sx={{
            animation: open
              ? 'slideUp 0.5s ease-in-out, fadeIn 0.5s ease-in-out'
              : 'none'
          }}
        >
          <Typography variant="bodyMediumSemibold" color="#475467">
            Activity Feed
          </Typography>
          <Stack direction="row" alignItems="center">
            <SvgWrapper
              icon={Opened}
              width="1rem"
              height="1rem"
              styleOverrides={{ fill: '#98A2B3', mr: '0.5rem' }}
            />
            <Typography variant="bodyMediumMedium" color="#475467">
              Mark all as read
            </Typography>
            <SvgWrapper
              icon={Filter}
              width="0.75rem"
              height="0.75rem"
              styleOverrides={{
                fill: 'none',
                stroke: '#667085',
                ml: '1.25rem'
              }}
            />
          </Stack>
        </Stack>

        <TabContainer
          variant="fullWidth"
          tabs={tabs}
          styleOverrides={{
            height: 'inherit',
            animation: open ? 'fadeIn 0.5s ease-in-out' : 'none'
          }}
        />
      </Box>
    </Drawer>
  );
};

export default NotificationsDrawer;
