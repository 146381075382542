import React, { useContext, useState } from 'react';
import { Box, Button as MUIButton, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronUp } from 'assets/svg/chevron-up.svg';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrowLeft.svg';
import { SvgWrapper } from 'components/svg';
import {
  CreateProductReceiptContext,
  FormValues
} from '../../context/createProductReceiptContext';
import _ from 'lodash';

type ReviewBatchPRCProps = {
  bankOptions: {
    title: string;
    value: string;
  }[];
  customerOptions: {
    title: string;
    value: string;
  }[];
  productOptions: {
    title: string;
    value: string;
  }[];
};

const ReviewBatchProductReceipts = ({
  bankOptions,
  customerOptions,
  productOptions
}: ReviewBatchPRCProps) => {
  const { data } = useContext(CreateProductReceiptContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const prcs = data.map((item: FormValues) => ({
    quantity: item.quantity,
    amount: item.amount,
    expiry_date: item.expiry_date
  }));

  const bankName = (bank: FormValues['bank']) => {
    const element = bankOptions.find(item => item.value === bank);
    return element?.title !== undefined ? element.title : '---';
  };

  const customerName = (customer: FormValues['customer_name']) => {
    const element = customerOptions.find(item => item.value === customer);
    return element?.title !== undefined ? element.title : '---';
  };

  const productName = (product: FormValues['customer_name']) => {
    const element = productOptions.find(item => item.value === product);
    return element?.title !== undefined ? element.title : '---';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'inherit',
        width: '29rem',
        margin: '0 auto',
        marginTop: '1.5rem'
      }}
    >
      <Box
        sx={{
          border: '0.063rem solid #F5F6F7',
          borderRadius: '.75rem',
          mb: '1.12rem',
          background: '#fff'
        }}
      >
        <Stack
          display="flex"
          flexDirection="column"
          sx={{
            padding: '1rem',
            paddingTop: '1.25rem',
            borderBottom: '.063rem solid #F5F6F7',
            gap: '.25rem'
          }}
        >
          <Typography variant="h6Bold" color="#475467">
            Review Details
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Ensure product receipt details are accurate
          </Typography>
        </Stack>

        <Box padding="1rem">
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingBottom={isOpen ? '.75rem' : '0rem'}
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Typography variant="bodyMediumSemibold" color="#667085">
              Primary Details
            </Typography>

            <SvgWrapper
              icon={isOpen ? ChevronUp : ChevronDown}
              width="1.25rem"
              height="1.25rem"
              styleOverrides={{
                fill: 'none',
                stroke: '#667085'
              }}
            />
          </Stack>

          {isOpen ? (
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              sx={{
                pb: '0rem'
              }}
            >
              <Stack
                direction="column"
                width="inherit"
                justifyContent="space-between"
              >
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Customer
                  </Typography>
                  <Typography
                    variant="captionLarge"
                    color="#475467"
                    width="55%"
                  >
                    {_.capitalize(customerName(data[0].customer_name))}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Lender
                  </Typography>
                  <Typography
                    variant="captionLarge"
                    color="#475467"
                    width="55%"
                  >
                    {_.capitalize(bankName(data[0].bank))}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Product
                  </Typography>
                  <Typography
                    variant="captionLarge"
                    color="#475467"
                    width="55%"
                  >
                    {_.capitalize(productName(data[0].product))}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    No of Product Receipts
                  </Typography>
                  <Typography
                    variant="captionLarge"
                    color="#475467"
                    width="55%"
                  >
                    {data.length}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box
        padding="1rem .75rem"
        sx={{
          border: '0.063rem solid #F5F6F7',
          borderRadius: '.75rem',
          background: '#fff',
          width: '29rem',
          pb: '0rem'
        }}
      >
        <Box
          sx={{
            maxHeight: '25rem',
            overflowY: 'auto'
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            sx={{
              borderRadius: '0.5rem',
              border: '0.0625rem solid #F5F6F7',
              background: 'rgba(102, 112, 133, 0.02)',
              pb: '0rem',
              mb: '1rem'
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderBottom: '1px solid #F5F6F7',
                width: '100%',
                padding: '1rem .75rem'
              }}
            >
              <Typography variant="bodyMediumSemibold" color="#475467">
                PRC {currentIndex + 1}
              </Typography>
            </Stack>

            <Stack
              direction="column"
              width="inherit"
              justifyContent="space-between"
              padding="0rem .75rem"
            >
              <Stack
                direction="row"
                width="inherit"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Unit
                </Typography>
                <Typography variant="captionLarge" color="#475467" width="55%">
                  {_.capitalize(`${prcs[currentIndex].quantity}`) || '---'}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                width="inherit"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Price
                </Typography>
                <Typography variant="captionLarge" color="#475467" width="55%">
                  ₦{_.capitalize(`${prcs[currentIndex].amount}`) || '0'}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                width="inherit"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Expiry Date
                </Typography>
                <Typography variant="captionLarge" color="#475467" width="55%">
                  {_.capitalize(`${prcs[currentIndex].expiry_date}`) || '---'}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>

        <Stack
          display="flex"
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            padding: '.75rem 0rem',
            borderTop: '.063rem solid #F5F6F7'
          }}
        >
          <Typography
            variant="bodyMediumSemibold"
            color="#98A2B3"
            marginRight="1rem"
          >
            {currentIndex + 1}/{prcs.length}
          </Typography>

          <Box gap="1rem">
            {currentIndex !== 0 && (
              <MUIButton
                variant="outlined"
                sx={{
                  border: 'none',
                  stroke: '#3E7DF8',
                  '&:hover': {
                    border: 'none'
                  },
                  '&:active': {
                    border: 'none'
                  },
                  '&:disabled': {
                    border: 'none',
                    background: 'grey'
                  }
                }}
                onClick={() => {
                  setCurrentIndex(currentIndex - 1);
                }}
              >
                <Typography variant="bodyMediumSemibold" color="#3E7DF8">
                  Back
                </Typography>
              </MUIButton>
            )}

            <MUIButton
              variant="outlined"
              sx={{
                border: 'none',
                stroke: '#3E7DF8',
                '&:hover': {
                  border: 'none'
                },
                '&:active': {
                  border: 'none'
                },
                '&:disabled': {
                  border: 'none',
                  display: 'none'
                }
              }}
              endIcon={<ArrowLeft />}
              onClick={() => {
                setCurrentIndex(currentIndex + 1);
              }}
              disabled={prcs.length - 1 === currentIndex}
            >
              <Typography variant="bodyMediumSemibold" color="#3E7DF8">
                Next
              </Typography>
            </MUIButton>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default ReviewBatchProductReceipts;
