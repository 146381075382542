import React, { useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/svg/chevron-up.svg';
import { SvgWrapper } from 'components/svg';
import { ProductReceiptApiData } from 'services/types/productReceipt';
import ProductReceiptDetailsCard from './productReceiptDetailsCard';
import BatchProductReceiptsDetailsTable from './batchProductReceiptDetailsTable';

const ProductReceiptDetails = ({
  productReceipt
}: {
  productReceipt: ProductReceiptApiData;
}) => {
  const [showBatchReceipts, setShowBatchReceipts] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState<{
    quoted_price: number;
    total_quantity: number;
  } | null>(null);

  const currentReceipt = selectedReceipt || productReceipt;

  return (
    <Box
      sx={{
        width: 'inherit',
        maxWidth: '80rem',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <ProductReceiptDetailsCard
        holders_name={productReceipt.holders[1].name}
        quoted_price={currentReceipt.quoted_price}
        product_name={productReceipt.product.name}
        total_quantity={currentReceipt.total_quantity}
        unit={productReceipt.product.unit}
        status={productReceipt.status}
      />

      {productReceipt.batch_id && (
        <Paper
          elevation={0}
          sx={{
            margin: '0 auto',
            mt: '2rem',
            padding: '.75rem',
            borderRadius: '0.75rem',
            backgroundColor: '#FFF',
            border: '0.0625rem solid #F5F6F7',
            width: {
              lg: '100%',
              xl: '70%'
            },
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          onClick={() => setShowBatchReceipts(!showBatchReceipts)}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '.25rem',
              justifyContent: 'center'
            }}
          >
            <Typography variant="bodyMediumSemibold" color="#475467">
              {showBatchReceipts ? 'Show Less' : 'Show all Receipts'}
            </Typography>
            <SvgWrapper
              icon={showBatchReceipts ? ChevronUp : ChevronDown}
              width={showBatchReceipts ? '1.25rem' : '1rem'}
              height={showBatchReceipts ? '1.25rem' : '1rem'}
              styleOverrides={{
                stroke: '#475467',
                fill: 'none',
                mb: 0.25
              }}
            />
          </Box>
        </Paper>
      )}

      {showBatchReceipts && (
        <Box width="100%" height="20rem" overflow="auto">
          <BatchProductReceiptsDetailsTable
            data={productReceipt.related_product_receipts}
            onRowClick={setSelectedReceipt}
          />
        </Box>
      )}
    </Box>
  );
};

export default ProductReceiptDetails;
