import * as Yup from 'yup';

export const createProductReceiptValidator = Yup.object({
  customer_name: Yup.string().required('This field is required'),
  bank: Yup.string().required('This field is required'),
  product: Yup.string().required('This field is required'),
  quantity: Yup.string()
    .required('This field is required')
    .matches(/^[0-9,]+$/, 'Quantity should be a valid number'),
  amount: Yup.string()
    .required('This field is required')
    .matches(/^[0-9,.₦]+$/, 'Amount should be a valid number'),
  expiry_date: Yup.string()
});

export const BatchCreateProductReceiptValidator = Yup.object({
  customer_name: Yup.string().required('This field is required'),
  bank: Yup.string().required('This field is required'),
  product: Yup.string().required('This field is required'),
  quantity: Yup.array()
    .of(Yup.string().matches(/^[0-9,]+$/, 'Quantity should be a valid number'))
    .required('This field is required'),
  amount: Yup.array()
    .of(Yup.string().matches(/^[0-9,.₦]+$/, 'Amount should be a valid number'))
    .required('This field is required'),
  expiry_date: Yup.array().of(Yup.string()).required('This field is required')
});
