import React, { useContext, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as InfoIcon } from 'assets/svg/infoPrimary.svg';
import {
  FormAutocomplete,
  FormDateInput,
  FormInput,
  FormSelect
} from 'components/form';
import { SvgWrapper } from 'components/svg';
import {
  CreateProductReceiptContext,
  FormValues,
  RequestDetails
} from '../../context/createProductReceiptContext';
import { createProductReceiptValidator } from '../../validator/createProductReceiptValidator';

type SinglePRCProps = {
  request: RequestDetails | null;
  bankOptions: {
    title: string;
    value: string;
  }[];
  customerOptions: {
    title: string;
    value: string;
  }[];
  productOptions: {
    title: string;
    value: string;
  }[];
};

const SingleProductReceiptDetails = ({
  request,
  bankOptions,
  customerOptions,
  productOptions
}: SinglePRCProps) => {
  const { data, setData } = useContext(CreateProductReceiptContext);

  const { control, watch } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: data[0],
    resolver: yupResolver(
      createProductReceiptValidator
    ) as unknown as Resolver<FormValues>
  });

  const bankId = (bank: FormValues['bank']) => {
    return bankOptions.find(item => item.title === bank);
  };

  const customerId = (customer: FormValues['customer_name']) => {
    return customerOptions.find(item => item.title === customer);
  };

  const values = watch();

  useEffect(() => {
    setData(prevData => [
      {
        ...prevData[0],
        customer_name:
          customerId(values.customer_name)?.value || values.customer_name,
        bank: bankId(values.bank)?.value || values.bank,
        product: values.product,
        quantity: values.quantity.replace(/,/g, ''),
        amount: values.amount.replace(/₦/g, ''),
        expiry_date: values.expiry_date
      }
    ]);
  }, [values]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'inherit',
        width: '29rem',
        margin: '0 auto',
        border: '0.063rem solid #F5F6F7',
        borderRadius: '.75rem',
        background: '#fff',
        marginTop: '1.5rem'
      }}
    >
      <Stack
        display="flex"
        flexDirection="column"
        sx={{
          padding: '1rem',
          paddingTop: '1.25rem',
          borderBottom: '.063rem solid #F5F6F7',
          gap: '.25rem'
        }}
      >
        <Typography variant="h6Bold" color="#475467">
          Single Product Receipt
        </Typography>
        <Typography variant="bodyMediumRegular" color="#667085">
          Enter details
        </Typography>
      </Stack>

      <Box padding="1rem .75rem">
        {request !== null && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap=".75rem"
            sx={{
              borderRadius: '0.5rem',
              border: '.063rem solid #F2F4F7',
              mb: '1rem',
              padding: '.75rem 0rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '1.5rem',
                width: '1.5rem',
                borderRadius: '.5rem',
                backgroundColor: '#ECF2FE',
                padding: '0.375rem'
              }}
            >
              <SvgWrapper
                icon={InfoIcon}
                width=".75rem"
                height=".75rem"
                color="#3E7DF8"
              />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Typography variant="bodySmallSemibold" color="#344054">
                This product receipt is created by request
              </Typography>
              <Typography variant="bodySmallSemibold" color="#667085">
                PRC was requested by Globus bank on behalf of Hamalaya Group
              </Typography>
            </Box>
          </Stack>
        )}

        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          padding="1.25rem"
          sx={{
            borderRadius: '0.5rem',
            border: '0.0625rem solid #F5F6F7',
            background: 'rgba(102, 112, 133, 0.02)',
            pb: '0rem',
            maxHeight: '28rem',
            overflow: 'auto'
          }}
        >
          <Stack display="flex" flexDirection="column" width="inherit">
            <FormAutocomplete<FormValues>
              control={control}
              multiple={false}
              name="customer_name"
              label="CUSTOMER NAME"
              options={customerOptions.map(option => option.title)}
            />

            <FormAutocomplete<FormValues>
              control={control}
              multiple={false}
              name="bank"
              label="BANK"
              options={bankOptions.map(option => option.title)}
            />
            <FormSelect<FormValues>
              name="product"
              options={productOptions}
              label="PRODUCT"
              control={control}
              renderSelected={(value: string) => value}
            />
            <FormInput<FormValues>
              control={control}
              name="quantity"
              label="UNIT"
            />
            <FormInput<FormValues>
              control={control}
              name="amount"
              label="PRICE"
            />
            <FormDateInput<FormValues>
              control={control}
              name="expiry_date"
              label="EXPIRY DATE"
              format="YYYY-MM-DD"
              disablePast
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleProductReceiptDetails;
