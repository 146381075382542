import React, { useEffect, useState } from 'react';
import { Table } from 'components/table';

import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { Box, Stack, Typography } from '@mui/material';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as Lock } from 'assets/svg/lock-solid.svg';
import AvatarGroup from 'components/common/avatarGroup';
import { Button } from 'components/button';
import { ReactComponent as plus } from 'assets/svg/plus.svg';
import EditRoles from '../roles/editRoles';
import CreateRole from '../roles/createRole';
import { useGetRolesQuery } from 'services/api/permissionsApi';
import { useAppSelector } from 'services/hook';
import axios from 'axios';
import { axiosConfigHelper } from 'utilities/api';
import { Role } from '../types/rolesTypes';
import { RolesApiData } from 'services/types/permissions';

const renderPermissions = (data: string[]) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        padding="0.5rem"
        sx={{ background: '#F5F6F7', borderRadius: '0.5rem' }}
      >
        <SvgWrapper height="1rem" width="1rem" icon={Lock} color="#98A2B3" />
      </Box>
      <Typography variant="bodyMediumMedium" color="#475467">
        {`${data.length}+ Permissions`}
      </Typography>
    </Stack>
  );
};

const columnHelper = createColumnHelper<Role>();
const columns = [
  columnHelper.accessor('role', {
    size: 200,
    cell: info => (
      <Stack display="flex" direction="column" alignItems="flex-start">
        <Typography variant="bodyMediumMedium" color="#344054">
          {info.getValue().name}
        </Typography>
        <Typography variant="bodyMediumRegular" color="#667085">
          {info.getValue().description}
        </Typography>
      </Stack>
    )
  }),
  columnHelper.accessor('permissions', {
    cell: info => <>{renderPermissions(info.getValue())}</>
  }),
  columnHelper.accessor('users', {
    cell: info => {
      const users = info.getValue();
      const profilePictures = users.map(user => user.profile_picture);
      return (
        <AvatarGroup type="img" srcs={profilePictures} maxShown={8} size="sm" />
      );
    }
  })
] as ColumnDef<Role>[];
// Cast is a workaround due to issues with tanstack table
// See https://github.com/TanStack/table/issues/4302

const RolesTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const { organizationId } = useAppSelector(state => state.auth);
  const { data: roles, isLoading: isLoadingRoles } = useGetRolesQuery({});

  const [rolesData, setRolesData] = useState<Role[]>([]);

  const [selectedRole, setSelectedRole] = useState<Role | null>(null);

  const handleRowClick = (role: Role) => {
    setSelectedRole(role);
    setIsOpen(true);
  };

  const handleButtonClick = () => {
    setIsCreateOpen(true);
  };

  useEffect(() => {
    if (roles) {
      const fetchDetails = async () => {
        const rolesData = await Promise.all(
          roles.map(async (role: RolesApiData) => {
            const users = await axios.get(
              `${organizationId}/roles/${role.role_id}/bearers/`,
              axiosConfigHelper({
                apiVersion: 'v1',
                contentType: 'application/json'
              })
            );
            return {
              role: {
                role_id: role.role_id,
                name: role.name,
                description: role.description,
                is_taficasa_default: role.is_taficasa_default
              },
              role_id: role.role_id,
              users: users.data || [],
              permissions: role.permissions || []
            };
          })
        );
        setRolesData(rolesData);
      };
      fetchDetails();
    }
  }, [roles]);

  return (
    <>
      {selectedRole && (
        <EditRoles
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            setSelectedRole(null);
          }}
          data={selectedRole}
        />
      )}
      {isCreateOpen && (
        <CreateRole
          open={isCreateOpen}
          onClose={() => {
            setIsCreateOpen(false);
          }}
          onCreate={handleRowClick}
        />
      )}
      <Table<Role>
        hover
        columns={columns}
        data={rolesData}
        onClickRow={handleRowClick}
        optionsButton
        filterColumns={['name']}
        searchPlaceholderText="Search for any role e.g Chief risk officer"
        enableFilter={false}
        headerButton={
          <Button
            size="sm"
            color="primary"
            icon={plus}
            iconPosition="start"
            text="Create Roles"
            styleOverrides={{ ml: '1rem' }}
            onClick={handleButtonClick}
          />
        }
        loadingTable={isLoadingRoles}
      />
    </>
  );
};

export default RolesTable;
