import React, { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Box, Stack, Typography } from '@mui/material';
import { theme } from 'themes/theme';
import { useAppSelector } from 'services/hook';
import Loading from 'components/loader/loading';
import IndicatorCard from './cards/indicatorCard';
import WorkflowCard from './cards/workflowCard';
import { DashboardProductReceiptTable } from './tables';
import { useGetUserQuery } from 'services/api/userApi';
import { useGetUserWorkflowQuery } from 'services/api/workflowApi';
import { useGetPermissionsQuery } from 'services/api/permissionsApi';
import { useGetRecentProductReceiptsQuery } from 'services/api/productReceiptApi';
import { ProductReceiptApiData } from 'services/types/productReceipt';
import { PermissionType } from 'services/enums/permissions';
import { useGetProductReceiptsSummaryQuery } from 'services/api/analyticsApi';
import { formattedNumber } from 'utilities/helperFunc/formatter';

const DashboardPage = () => {
  const { data: userData } = useGetUserQuery({});
  const { organizationType } = useAppSelector(state => state.auth);

  const { data: permissions, isLoading: isLoadingPermissions } =
    useGetPermissionsQuery({});

  const hasProductReceiptViewVaultPermission = permissions?.includes(
    PermissionType.PRODUCT_RECEIPT_VIEW_VAULT
  );
  const hasWorkflowViewPermission = permissions?.includes(
    PermissionType.WORKFLOW_VIEW
  );

  const { data: productReceiptData, isLoading } =
    useGetRecentProductReceiptsQuery(
      hasProductReceiptViewVaultPermission
        ? {
            params: {
              count: '5'
            }
          }
        : skipToken
    );

  const { data: activeWorkflowData } = useGetUserWorkflowQuery(
    hasWorkflowViewPermission ? {} : skipToken
  );

  const { data: productReceiptSummary } = useGetProductReceiptsSummaryQuery(
    hasProductReceiptViewVaultPermission
      ? {
          params: {
            start: '2024-01-01'
          }
        }
      : skipToken
  );

  const indicatorItems = productReceiptSummary
    ? [
        {
          title: 'Total PRC Value',
          value: formattedNumber(productReceiptSummary.total_value, true)
        },
        {
          title: 'Total PRC Issued',
          value: formattedNumber(productReceiptSummary.total_count)
        },
        {
          title: 'Pickup Rates',
          value: `${productReceiptSummary.pickup_rate}%`
        }
      ]
    : null;

  const transformedProductReceiptData = useMemo(() => {
    const emptyProductReceiptData: ProductReceiptApiData[] = [];
    if (productReceiptData) {
      return Object.values(productReceiptData);
    }
    return emptyProductReceiptData;
  }, [productReceiptData]);

  if (isLoadingPermissions) {
    return <Loading loading={isLoadingPermissions} />;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: '2.06rem',
          backgroundColor: '#FCFCFC'
        }}
      >
        <Stack width="inherit" sx={{ textAlign: 'left' }}>
          <Typography
            variant="h6Bold"
            sx={{
              color: `${theme.palette.common.subheading}`,
              fontWeight: 600,
              lineHeight: '1.5rem'
            }}
          >
            Welcome Back, {userData?.firstname} {userData?.lastname}
          </Typography>
        </Stack>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1.375rem',
          width: 'inherit'
        }}
      >
        {indicatorItems?.map((item, index) => (
          <IndicatorCard key={index} title={item.title} value={item.value} />
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          marginTop: '2rem',
          width: 'inherit',
          padding: '0.5rem',
          borderRadius: '0.5rem',
          border: '.063rem solid #F5F6F7',
          backgroundColor: '#FFF'
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5rem',
            backgroundColor: '#FCFCFD',
            border: '.063rem solid #F5F6F7',
            borderRadius: '0.5rem',
            padding: '0.75rem'
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            width="100%"
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              flex="1 0 0"
            >
              <Typography variant="bodyMediumSemibold">
                Active Workflows
              </Typography>
              <Typography
                variant="bodyMediumMedium"
                color="#98A2B3"
                sx={{ width: 'auto' }}
              >
                View updates for all workflows
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1}>
            {hasWorkflowViewPermission && (
              <>
                {activeWorkflowData?.map(workflow =>
                  Object.keys(workflow.steps).map(stepId => {
                    const step = workflow.steps[Number(stepId)];
                    if (step.is_actionable) {
                      return (
                        <WorkflowCard
                          key={stepId}
                          workflow={workflow}
                          step={step}
                        />
                      );
                    }
                    return null;
                  })
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Box>

      <Box
        sx={{
          marginTop: '2rem',
          width: 'inherit'
        }}
      >
        <DashboardProductReceiptTable
          data={transformedProductReceiptData}
          isLoading={isLoading}
          organizationType={organizationType}
        />
      </Box>
    </Box>
  );
};

export default DashboardPage;
