import React, { useContext } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  CreateProductReceiptContext,
  FormValues
} from '../../context/createProductReceiptContext';
import _ from 'lodash';

type ReviewSinglePRCProps = {
  bankOptions: {
    title: string;
    value: string;
  }[];
  customerOptions: {
    title: string;
    value: string;
  }[];
  productOptions: {
    title: string;
    value: string;
  }[];
};

const ReviewSingleProductReceipts = ({
  bankOptions,
  customerOptions,
  productOptions
}: ReviewSinglePRCProps) => {
  const { data } = useContext(CreateProductReceiptContext);

  const bankName = (bank: FormValues['bank']) => {
    const element = bankOptions.find(item => item.value === bank);
    return element?.title !== undefined ? element.title : '---';
  };

  const customerName = (customer: FormValues['customer_name']) => {
    const element = customerOptions.find(item => item.value === customer);
    return element?.title !== undefined ? element.title : '---';
  };

  const productName = (product: FormValues['customer_name']) => {
    const element = productOptions.find(item => item.value === product);
    return element?.title !== undefined ? element.title : '---';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'inherit',
        width: '29rem',
        margin: '0 auto',
        border: '0.063rem solid #F5F6F7',
        borderRadius: '.75rem',
        background: '#fff',
        marginTop: '1.5rem'
      }}
    >
      <Stack
        display="flex"
        flexDirection="column"
        sx={{
          padding: '1rem',
          paddingTop: '1.25rem',
          borderBottom: '.063rem solid #F5F6F7',
          gap: '.25rem'
        }}
      >
        <Typography variant="h6Bold" color="#475467">
          Review Details
        </Typography>
        <Typography variant="bodyMediumRegular" color="#667085">
          Ensure product receipt details are accurate
        </Typography>
      </Stack>

      <Box padding=".5rem 0rem">
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          padding=".5rem .75rem"
        >
          <Stack
            direction="column"
            width="inherit"
            justifyContent="space-between"
            padding="0rem .75rem"
          >
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Customer
              </Typography>
              <Typography variant="captionLarge" color="#475467" width="55%">
                {_.capitalize(customerName(data[0].customer_name))}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Bank
              </Typography>
              <Typography variant="captionLarge" color="#475467" width="55%">
                {_.capitalize(bankName(data[0].bank))}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Product
              </Typography>
              <Typography variant="captionLarge" color="#475467" width="55%">
                {_.capitalize(productName(data[0].product))}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Unit
              </Typography>
              <Typography variant="captionLarge" color="#475467" width="55%">
                {_.capitalize(`${data[0].quantity}`) || '---'}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Price
              </Typography>
              <Typography variant="captionLarge" color="#475467" width="55%">
                ₦{_.capitalize(`${data[0].amount}`) || '0'}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Expiry Date
              </Typography>
              <Typography variant="captionLarge" color="#475467" width="55%">
                {_.capitalize(`${data[0].expiry_date}`) || '---'}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewSingleProductReceipts;
