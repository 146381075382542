import React, { useContext, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as ChevronUp } from 'assets/svg/chevron-up.svg';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { ReactComponent as InfoIcon } from 'assets/svg/infoPrimary.svg';
import { Accordion } from 'components/accordion';
import { Button } from 'components/button';
import {
  FormAutocomplete,
  FormDateInput,
  FormInput,
  FormSelect
} from 'components/form';
import { SvgWrapper } from 'components/svg';
import { BatchCreateProductReceiptValidator } from '../../validator/createProductReceiptValidator';
import {
  CreateProductReceiptContext,
  RequestDetails
} from '../../context/createProductReceiptContext';

type FormValues = {
  customer_name: string;
  bank: string;
  product: string;
  quantity: string[];
  amount: string[];
  expiry_date: string[];
};

type BatchPRCProps = {
  request: RequestDetails | null;
  bankOptions: {
    title: string;
    value: string;
  }[];
  customerOptions: {
    title: string;
    value: string;
  }[];
  productOptions: {
    title: string;
    value: string;
  }[];
};

const BatchProductReceiptDetails = ({
  request,
  bankOptions,
  customerOptions,
  productOptions
}: BatchPRCProps) => {
  const { data, setData } = useContext(CreateProductReceiptContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [prcList, setPrcList] = useState<number[]>([1]);

  const { control, watch } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      customer_name: data[0].customer_name,
      bank: data[0].bank,
      product: data[0].product,
      quantity:
        data.length > 1
          ? data.map(item => String(item.quantity))
          : [data[0].quantity],
      amount:
        data.length > 1
          ? data.map(item => String(item.amount))
          : [data[0].amount],
      expiry_date:
        data.length > 1
          ? data.map(item => String(item.expiry_date))
          : [data[0].expiry_date]
    },
    resolver: yupResolver(
      BatchCreateProductReceiptValidator
    ) as unknown as Resolver<FormValues>
  });

  const bankId = (bank: FormValues['bank']) => {
    return bankOptions.find(item => item.title === bank);
  };

  const customerId = (customer: FormValues['customer_name']) => {
    return customerOptions.find(item => item.title === customer);
  };

  const values = watch();

  useEffect(() => {
    const formattedData = values.quantity.map((_, index) => ({
      customer_name:
        customerId(values.customer_name)?.value || values.customer_name,
      bank: bankId(values.bank)?.value || values.bank,
      product: values.product,
      quantity: values.quantity[index] || '',
      amount: values.amount[index] || '',
      expiry_date: values.expiry_date[index] || ''
    }));

    setData(formattedData);
  }, [values]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'inherit',
        width: '29rem',
        margin: '0 auto',
        marginTop: '1.5rem'
      }}
    >
      <Box
        sx={{
          border: '0.063rem solid #F5F6F7',
          borderRadius: '.75rem',
          background: '#fff',
          mb: '1.12rem'
        }}
      >
        <Stack
          display="flex"
          flexDirection="column"
          sx={{
            padding: '1rem',
            paddingTop: '1.25rem',
            borderBottom: '.063rem solid #F5F6F7',
            gap: '.25rem'
          }}
        >
          <Typography variant="h6Bold" color="#475467">
            Batch Product Receipt
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Enter details
          </Typography>
        </Stack>

        <Box padding="1rem .75rem">
          {request !== null && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap=".75rem"
              sx={{
                borderRadius: '0.5rem',
                border: '.063rem solid #F2F4F7',
                mb: '1rem',
                padding: '.75rem 0rem'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '1.5rem',
                  width: '1.5rem',
                  borderRadius: '.5rem',
                  backgroundColor: '#ECF2FE',
                  padding: '0.375rem'
                }}
              >
                <SvgWrapper
                  icon={InfoIcon}
                  width=".75rem"
                  height=".75rem"
                  color="#3E7DF8"
                />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
              >
                <Typography variant="bodySmallSemibold" color="#344054">
                  This product receipt is created by request
                </Typography>
                <Typography variant="bodySmallSemibold" color="#667085">
                  PRC was requested by Globus bank on behalf of Hamalaya Group
                </Typography>
              </Box>
            </Stack>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            padding={isOpen ? '1rem 0rem' : '0rem'}
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Typography variant="bodyMediumSemibold" color="#667085">
              Primary Details
            </Typography>

            <SvgWrapper
              icon={isOpen ? ChevronUp : ChevronDown}
              width="1.25rem"
              height="1.25rem"
              styleOverrides={{
                fill: 'none',
                stroke: '#667085'
              }}
            />
          </Stack>

          {isOpen ? (
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              padding="1.25rem"
              sx={{
                borderRadius: '0.5rem',
                border: '0.0625rem solid #F5F6F7',
                background: 'rgba(102, 112, 133, 0.02)',
                pb: '0rem'
              }}
            >
              <Stack display="flex" flexDirection="column" width="inherit">
                <FormAutocomplete<FormValues>
                  control={control}
                  multiple={false}
                  name="customer_name"
                  label="CUSTOMER NAME"
                  options={customerOptions.map(option => option.title)}
                />

                <FormAutocomplete<FormValues>
                  control={control}
                  multiple={false}
                  name="bank"
                  label="BANK"
                  options={bankOptions.map(option => option.title)}
                />
                <FormSelect<FormValues>
                  name="product"
                  options={productOptions}
                  label="PRODUCT"
                  control={control}
                  renderSelected={(value: string) => value}
                />
              </Stack>
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box
        padding="1rem .75rem"
        sx={{
          border: '0.063rem solid #F5F6F7',
          borderRadius: '.75rem',
          background: '#fff',
          width: '29rem',
          pb: '0rem'
        }}
      >
        <Box
          sx={{
            maxHeight: '25rem',
            overflow: 'auto'
          }}
        >
          {prcList.map((_, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="row"
              width="100%"
              sx={{
                borderRadius: '0.5rem',
                border: '0.0625rem solid #F5F6F7',
                background: 'rgba(102, 112, 133, 0.02)',
                pb: '0rem',
                mb: '1rem'
              }}
            >
              <Accordion
                summary={
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: '0.75rem'
                    }}
                  >
                    <Typography variant="bodyMediumSemibold" color="#475467">
                      PRC {index + 1}
                    </Typography>
                  </Stack>
                }
              >
                <Stack
                  display="flex"
                  flexDirection="column"
                  gap="1rem"
                  mt="1rem"
                >
                  <FormInput<FormValues>
                    control={control}
                    name={`quantity.${index}`}
                    label="UNIT"
                  />
                  <FormInput<FormValues>
                    control={control}
                    name={`amount.${index}`}
                    label="PRICE"
                  />
                  <FormDateInput<FormValues>
                    control={control}
                    name={`expiry_date.${index}`}
                    label="EXPIRY DATE"
                    format="YYYY-MM-DD"
                    disablePast
                  />
                </Stack>
              </Accordion>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            padding: '.75rem 0rem',
            borderTop: '.063rem solid #F5F6F7',
            zIndex: 2
          }}
        >
          <Button
            styleOverrides={{ marginLeft: 'auto', border: 'none' }}
            text="Add PRC"
            transparent
            color="primary"
            size="md"
            icon={PlusIcon}
            iconPosition="start"
            onClick={() =>
              setPrcList(prevList => [...prevList, prevList.length + 1])
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BatchProductReceiptDetails;
