import { api } from './baseApi';
import {
  AggregateProductReceiptAnalyticsApiData,
  MonthlyProductReceiptAnalyticsApiData,
  TopBranchesAnalyticsApiData,
  TopOrganizationsAnalyticsApiData,
  TopProductsAnalyticsApiData
} from 'services/types/analytics';

export type AggregatePRCsQueryArgs = {
  params: {
    start: string;
    end?: string;
    include_closed?: 'true' | 'false';
    granularity: 'daily' | 'monthly' | 'yearly';
    branches?: string;
  };
};

export type MonthlyPRCsQueryArgs = {
  params: {
    start: string;
    branches?: string;
  };
};

type GenericTopObjectQueryArgs = {
  params: {
    start: string;
    end?: string;
    include_closed?: 'true' | 'false';
  };
};

export const analyticsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAggregateProductReceipts: builder.query<
      AggregateProductReceiptAnalyticsApiData[],
      AggregatePRCsQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = new URLSearchParams(params).toString();
        return {
          url: `{supplyChainProfileId}/product_receipts/analytics/aggregate/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getProductReceiptsSummary: builder.query<
      MonthlyProductReceiptAnalyticsApiData,
      MonthlyPRCsQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = new URLSearchParams(params).toString();
        return {
          url: `{supplyChainProfileId}/product_receipts/analytics/summary/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getTopProducts: builder.query<
      TopProductsAnalyticsApiData[],
      GenericTopObjectQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = new URLSearchParams(params).toString();
        return {
          url: `{supplyChainProfileId}/product_receipts/analytics/top-products/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getTopMerchants: builder.query<
      TopOrganizationsAnalyticsApiData[],
      GenericTopObjectQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = new URLSearchParams(params).toString();
        return {
          url: `{supplyChainProfileId}/product_receipts/analytics/top-merchants/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getTopBranches: builder.query<
      TopBranchesAnalyticsApiData[],
      GenericTopObjectQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = new URLSearchParams(params).toString();
        return {
          url: `{supplyChainProfileId}/product_receipts/analytics/top-branches/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    })
  }),
  overrideExisting: false
});

export const {
  useLazyGetAggregateProductReceiptsQuery,
  useGetAggregateProductReceiptsQuery,
  useGetProductReceiptsSummaryQuery,
  useGetTopBranchesQuery,
  useGetTopMerchantsQuery,
  useGetTopProductsQuery,
  useLazyGetTopBranchesQuery,
  useLazyGetTopProductsQuery,
  usePrefetch
} = analyticsApi;
