import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import ProductReceiptsTable from './tables/productReceiptsTable';
import { useAppSelector } from 'services/hook';
import { ProductReceiptApiData } from 'services/types/productReceipt';
import { useGetCentralVaultProductReceiptsQuery } from 'services/api/productReceiptApi';

const ProductReceiptCentralVaultPage = () => {
  const { organizationType } = useAppSelector(state => state.auth);
  const { distributorId, merchantId } = useParams();
  const { data, isLoading } = useGetCentralVaultProductReceiptsQuery({
    distributorId: distributorId,
    merchantId: merchantId
  });

  const centralVaultData = useMemo(() => {
    const emptyCentralVaultData: ProductReceiptApiData[] = [];
    if (data) {
      return Object.values(data.results);
    }
    return emptyCentralVaultData;
  }, [data]);

  return (
    <Box>
      <ProductReceiptsTable
        data={centralVaultData}
        organizationType={organizationType}
        loading={isLoading}
      />
    </Box>
  );
};

export default ProductReceiptCentralVaultPage;
