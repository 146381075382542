import {
  ProductReceiptApiData,
  ProductPickupRequestApiData,
  ProductReceiptCollectionDepotApiData
} from 'services/types/productReceipt';
import { WithIdTransformed } from 'services/types/common';
import { api } from './baseApi';
import { WithPagination } from 'services/types/common';

type ReleaseProductReceiptQueryArgs = {
  productReceiptId: string;
  data: {
    pickup_code: string;
  };
};

type AssignToBranchQueryArgs = {
  productReceiptId: string;
  data: {
    branch: string;
  };
};

type SchedulePickupArgs = {
  pickupRequestId: string;
  productReceiptId: string;
  data: {
    collection_depot: string;
    pickup_date: string;
  };
};

type GetBranchVaultQueryArgs = {
  distributorId: string;
  merchantId: string;
  params: {
    branch: string;
  };
};

type GetRecentProductReceiptQueryArgs = {
  params: {
    count: string;
  };
};

type CreatePickupRequestQueryArgs = {
  productReceiptId: string;
  data: {
    firstname: string;
    lastname: string;
    email: string;
    phone_number: string;
    profile_picture: File | null;
    vehicle_make: string | null;
    vehicle_model: string | null;
    vehicle_plate_number: string | null;
    vehicle_color: string | null;
    vehicle_photo: File | null;
  };
};

export type CreateProductReceiptQueryArgs = {
  customer: string;
  bank: string;
  product: string;
  document_files: File[];
  document_metadata: string[];
  total_quantity: number;
  quoted_price: number;
  quoted_price_currency: string;
  quoted_price_expiry: string;
};

type ValidatePickupCodeQueryArgs = {
  productReceiptId: string;
  data: {
    pickup_code: string;
  };
};

export const productReceiptApi = api.injectEndpoints({
  endpoints: builder => ({
    getProductReceipt: builder.query<
      ProductReceiptApiData,
      { productReceiptId: string }
    >({
      query: ({ productReceiptId }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId }
      ]
    }),
    createProductReceipt: builder.mutation<
      ProductReceiptApiData,
      CreateProductReceiptQueryArgs[]
    >({
      query: data => ({
        url: '{supplyChainProfileId}/product_receipts/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: ['productReceiptMetadata']
    }),
    getProductReceiptActions: builder.query({
      query: ({ productReceiptId }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/actions`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getCentralVaultProductReceipts: builder.query<
      WithPagination<WithIdTransformed<ProductReceiptApiData>>,
      unknown
    >({
      query: ({ distributorId, merchantId }) => ({
        url: `{supplyChainProfileId}/product_receipts/vault/central/${merchantId}/${distributorId}`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getBranchVaultProductReceipts: builder.query<
      WithPagination<WithIdTransformed<ProductReceiptApiData>>,
      GetBranchVaultQueryArgs
    >({
      query: ({ params, distributorId, merchantId }) => {
        const queryParams = new URLSearchParams(params).toString();
        return {
          url: `{supplyChainProfileId}/product_receipts/vault/branch/${merchantId}/${distributorId}/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getRecentProductReceipts: builder.query<
      WithIdTransformed<ProductReceiptApiData>,
      GetRecentProductReceiptQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = new URLSearchParams(params).toString();
        return {
          url: `{supplyChainProfileId}/product_receipts/recent/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    releaseProductReceipt: builder.mutation<
      ProductReceiptApiData,
      ReleaseProductReceiptQueryArgs
    >({
      query: ({ productReceiptId, data }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/release/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId },
        'productReceiptMetadata'
      ]
    }),
    startBranchAssignmentWorkflow: builder.mutation<
      ProductReceiptApiData,
      { productReceiptId: string }
    >({
      query: ({ productReceiptId }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/assign/start_workflow/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId }
      ]
    }),
    assignProductReceiptToBranch: builder.mutation<
      ProductReceiptApiData,
      AssignToBranchQueryArgs
    >({
      query: ({ productReceiptId, data }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/assign/complete/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId },
        'productReceiptMetadata'
      ]
    }),
    startEndorsementWorkflow: builder.mutation<
      ProductReceiptApiData,
      { data: { product_receipts: string[] } }
    >({
      query: ({ data }) => ({
        url: '{supplyChainProfileId}/product_receipts/endorsement/start_workflow/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, { data }) =>
        data.product_receipts.map(id => ({
          type: 'productReceipts',
          id: id
        }))
    }),
    endorseProductReceipt: builder.mutation<
      ProductReceiptApiData,
      { data: { product_receipts: string[] } }
    >({
      query: ({ data }) => ({
        url: '{supplyChainProfileId}/product_receipts/endorsement/complete/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, { data }) =>
        data.product_receipts.map(id => ({
          type: 'productReceipts',
          id: id
        }))
    }),
    createPickupRequest: builder.mutation<
      ProductReceiptApiData,
      CreatePickupRequestQueryArgs
    >({
      query: ({ productReceiptId, data }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/pickup/request/`,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId }
      ]
    }),
    getPickupRequests: builder.query<ProductPickupRequestApiData[], unknown>({
      query: () => ({
        url: '{supplyChainProfileId}/product_receipts/pickup/request',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getPickupDepots: builder.query<
      ProductReceiptCollectionDepotApiData[],
      unknown
    >({
      query: () => ({
        url: '{supplyChainProfileId}/product_receipts/pickup/depot',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    generatePickupCode: builder.mutation<
      ProductReceiptApiData,
      { productReceiptId: string }
    >({
      query: ({ productReceiptId }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/pickup/code/generate/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId }
      ]
    }),
    schedulePickup: builder.mutation<
      ProductPickupRequestApiData,
      SchedulePickupArgs
    >({
      query: ({ pickupRequestId, data }) => ({
        url: `{supplyChainProfileId}/product_receipts/pickup/request/${pickupRequestId}/schedule/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId }
      ]
    }),
    validatePickupCode: builder.mutation<
      ProductReceiptApiData,
      ValidatePickupCodeQueryArgs
    >({
      query: ({ productReceiptId, data }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/pickup/code/validate/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId }
      ]
    })
  }),
  overrideExisting: false
});

export const {
  useGetProductReceiptQuery,
  useCreateProductReceiptMutation,
  useGetProductReceiptActionsQuery,
  useLazyGetProductReceiptQuery,
  useGetCentralVaultProductReceiptsQuery,
  useGetBranchVaultProductReceiptsQuery,
  useGetRecentProductReceiptsQuery,
  useGetPickupRequestsQuery,
  useGetPickupDepotsQuery,
  useValidatePickupCodeMutation,
  useCreatePickupRequestMutation,
  useSchedulePickupMutation,
  useReleaseProductReceiptMutation,
  useStartBranchAssignmentWorkflowMutation,
  useStartEndorsementWorkflowMutation,
  useAssignProductReceiptToBranchMutation,
  useEndorseProductReceiptMutation
} = productReceiptApi;
