import React from 'react';
import {
  Typography,
  Box,
  Tooltip as MUITooltip,
  Button,
  TooltipProps as MUITooltipProps
} from '@mui/material';

export enum ArrowOption {
  None = 'none',
  Show = 'show'
}
//Task 1047

export interface TooltipProps {
  header: string;
  subText?: string;
  color: string;
  placement?: MUITooltipProps['placement'];
  children: React.ReactNode;
  bgColor?: string;
  showArrow?: ArrowOption;
  onboarding?: boolean;
  captionText?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  header,
  subText,
  color = '#485468',
  placement = 'top',
  children,
  bgColor = '#FFF',
  showArrow = ArrowOption.Show,
  onboarding = false,
  captionText
}) => {
  const showArrowVisible = showArrow !== ArrowOption.None;

  return (
    <MUITooltip
      title={
        <Box
          sx={{
            borderRadius: '0.25rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: '0.875rem',
              fontWeight: 700,
              letterSpacing: '0.05em',
              textTransform: 'uppercase',
              color: color
            }}
          >
            {header}
          </Typography>

          {subText && (
            <Typography
              variant="caption"
              sx={{
                color: '#677186',
                marginBottom: onboarding ? '0.75rem' : '0.25rem'
              }}
            >
              {subText}
            </Typography>
          )}

          {onboarding && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Box
                sx={{
                  marginRight: 'auto',
                  fontSize: '0.625rem',
                  color: '#677186'
                }}
              >
                {captionText}
              </Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#2b63f2',
                  color: '#FFF',
                  fontSize: '0.75rem',
                  padding: '0.25rem 0.75rem',
                  textTransform: 'none',
                  borderRadius: '0.25rem'
                }}
              >
                Next
              </Button>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: '#FFF',
                  color: '#677186',
                  fontSize: '0.75rem',
                  padding: '0.25rem 0.75rem',
                  textTransform: 'none',
                  border: '1px solid #d9d9d9',
                  borderRadius: '0.25rem'
                }}
              >
                Dismiss
              </Button>
            </Box>
          )}
        </Box>
      }
      arrow={showArrowVisible}
      placement={placement}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: bgColor,
            borderRadius: '0.25rem',
            padding: '0.75rem',
            border: onboarding ? '1px solid #2b63f2' : 'none',
            boxShadow: onboarding
              ? '0 4px 12px rgba(66, 133, 244, 0.2)'
              : '0 4px 12px rgba(66, 133, 244, 0.2)'
          },
          '& .MuiTooltip-arrow': {
            color: onboarding ? '#e6f7ff' : bgColor,
            '&::before': {
              content: '""',
              position: 'absolute',
              width: '0',
              height: '0',
              borderStyle: 'solid',
              borderWidth: '6px 2px 0 6px',
              borderColor: '#2b63f2 transparent transparent transparent'
            }
          }
        }
      }}
    >
      <Box component="span">{children}</Box>
    </MUITooltip>
  );
};

export default Tooltip;
